import React from 'react';
import axios from 'axios';

import { ApiPaths } from './constants/ApiPaths';

const DeepLink = React.lazy(() => import('./DeepLink'));

const VersionCheck = () => {
    const [dbInfo, setDbInfo] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState('');

    const isMountedRef = React.useRef(null);

    // componentDidMount 
    React.useEffect(() => {
        isMountedRef.current = true;

        getDbInfo();

        // unmount
        return () => isMountedRef.current = false;
    }, []);

    const getDbInfo = () => {
        console.log("getDbInfo");

        axios.get(`${ApiPaths.DbInfo}`)
            .then(res => {
                console.log('getDbInfo', res.data);

                setDbInfo(res.data);
            }, error => {
                var errorMessage2 = 'Get DbInfo failed.  Please refresh again.';
                if (error.response) {
                    console.error(error.response);
                }
                else
                    console.error(error);
                setErrorMessage(errorMessage2);
            });
    }

    return (
        <>
            {dbInfo && dbInfo.web2uVersion === window.myVersion ?
                <>
                    <DeepLink />
                </>
                :
                <>
                    <div>
                        {errorMessage.length > 0 &&
                            <div>
                                <p>{errorMessage}</p>
                            </div>
                        }
                        {!dbInfo ?
                            <>
                                <div>
                                    <p>Checking PawnOnline Web version...</p>
                                </div>
                            </>
                            :
                            <>
                                {dbInfo.web2uVersion !== window.myVersion &&
                                    <div>
                                        <p>{`DORO2U telah dikemas kini ke versi ${dbInfo.web2uVersion}.  Sila kemas kini pelayar web anda untuk mendapatkan versi terkini.`}</p>
                                    </div>
                                }
                            </>
                        }
                    </div>
                </>
            }
        </>
        );
}

export default VersionCheck;